<template>
    <div class="row" style="margin-left:auto;margin-right:auto;">
    <div class="col-md-12 mx-auto">
    <label >Enter phone number to search patient details</label>
    <div class=" searchPatientText mt-2 mb-2">
    <div style="margin-right: 5px;">
    <input type="text" id="mobile" maxlength="10" name="mobile" class="form-control" @input="mobileNumberValidate" v-model="publicPayload.mobile" v-on:keyup.enter="isMobileValid ? listPatient() : null" />
    </div>
    <div class=" searchBtn" id="patient_list">
    <span>
    <button type="button" @click="listPatient()" class="btn btn-blue-color text-white"
    :disabled="!isMobileValid">Search</button>
    </span>
    <!-- </div> -->
    <!-- <div class="col-4 col-lg-4 col-md-4 col-sm-4" > -->
    <span>
    <button type="button" @click="clearPatient()" style="margin-left: 4px;" class="btn btn-color text-white"
    >Clear</button>
    </span>
    </div>
    </div>
    <div class="px-2">
    <div v-if="isNot && !openAddPatient" >
    <h3 class="text-center noRecordText mt-3">No records found</h3>
    </div>
    <div class="hr-border"></div>
    <div>
    <div class="row noRecordfoundMainDiv text-align text-center mt-2" v-if="isNot">
    <div class="mt-4" v-if="!enableResendBtnUser && !otpIsVerified">
    <button class="btn btn-blue-color text-white OtpButton" @click="GenerateOTP()" :disabled="!isConsultationDataValidNoCheckbox || !timegreater" :class=" !isConsultationDataValidNoCheckbox ? 'disableBtn': ''">Verify mobile number</button>
    </div>
    <div class=" mt-4" v-if="enableResendBtnUser && !otpIsVerified" >
    <button class="btn btn-color text-white tex-align text-center"  :disabled="!isConsultationDataValidNoCheckbox || !timegreater" @click="resendOTPUser" :class=" !isConsultationDataValidNoCheckbox ? 'disableBtn': ''">Resend OTP</button>
    </div>
    <span v-if="!otpIsVerified && enterOTP">
        <div class="EnterOTPdiv mt-2" >
        <label class="enterOTPLabelText" for="">Enter OTP</label>
        <div class="Otpinputs" style="width: 300px; display: flex;justify-content: center;"   >
        <input v-for="(number,index) in otpNumbersUser" ref="otpInputs" :key="index" class="m-2 text-center form-control rounded" type="text" maxlength="1" 
        v-model="otpNumbersUser[index]" @input="validateInputUser(index)" v-on:keyup.enter="combinedOTPUser ? verityOTPUser() : null" />
        </div>
        </div>
        <div class="mt-2" v-if="timeLeftUser > 0 && openOTP ">
    <p  >Resend OTP in <span id="countdowntimer">{{ timeLeftUser }}</span> Seconds</p>
    </div>  
        <div class="text-align text-center">
        <button class="btn btn-blue-color text-white tex-align text-center" :class="!combinedOTPUser ? 'disableBtn': ''" :disabled="!combinedOTPUser" @click="verityOTPUser()">
        Verify
        </button>
        </div>
    </span>
    <div class="addUserImg mb-3"  v-if="createPatient">
    <div class="text-center name-fix">Add patient</div>
    <img class="addPluesIcon"  src="@/assets/images/plus-add.png" @click="patientData()" />
    </div>
    </div>
    </div>
    <div class="curveBoxes" v-if="openAddPatient">
    <div  v-if="openAddPatient">
    <div class="row my-2">
    <div class="col-12 col-md-6 col-lg-6">
    <div class="form-group">
    <label class="">First Name<span class="star">*</span></label>
    <input type="text" id="first_name" name="first_name" class="form-control"
    v-model="publicPayload.firstName" :disabled="isUserCreated" v-on:keyup.enter="isFormFamilyInvalid ? addPatientData() : null" />
    </div>
    </div>
    <div class="col-12 col-md-6 col-lg-6">
    <div class="form-group">
    <label class="">Last Name<span class="star">*</span></label>
    <input type="text" id="last_name" name="last_name" class="form-control"
    v-model="publicPayload.lastName" :disabled="isUserCreated" v-on:keyup.enter="isFormFamilyInvalid ? addPatientData() : null" />
    </div>
    </div>
    </div>
    <div class="row my-2">
    <div class="col-6">
    <label class="">Age<span class="star">*</span></label>
    <input type="number" id="patient_age" name="patient_age" class="form-control" v-model="publicPayload.age" :disabled="isUserCreated" v-on:keyup.enter="isFormFamilyInvalid ? addPatientData() : null" />
    </div>
    <div class="col-6">
    <label for="" class="">Gender<span class="star">*</span></label>
    <select class="form-control" v-model="publicPayload.gender" :disabled="isUserCreated" v-on:keyup.enter="isFormFamilyInvalid ? addPatientData() : null">
    <option value="1" disabled >Select</option>
    <option value="Male">Male</option>
    <option value="Female">Female</option>
    <option value="Others">Others</option>
    </select>
    </div>
    </div>
    <div class="row my-2">
     <div class=" col-6">
    <label class="sign-up-feilds">Pincode<span class="star">*</span></label>
    <input class="form-control" aria-label="pincode" type="text"  maxlength="6"
    v-model="publicPayload.pincode" :disabled="isUserCreated" v-on:keyup.enter="isFormFamilyInvalid ? addPatientData() : null"  >
    </div>
    </div>
    <div class="row my-3">
    <div class="col-5 col-md-3 col-lg-3">
    <button class="btn btn-blue-color text-white" v-if="!this.openSave" :disabled="!isFormFamilyInvalid" @click="addPatientData"
    >Save</button>
    </div>
    </div>
    <hr class="hr-border" />
    </div>
    <div v-if="patientSupport == true">
    <div v-for="(records,index) in upload_Record" :key="records.index">
    <h5 class="taskmanager-title1 text-align text-center" v-show="date">{{ date }}</h5>
    <div class="row mt-2">
    <div class="col-sm-12">
    <p class="">Case sheet<span class="star">*</span></p>
    </div>
    </div>
    <div class="row mb-2">
    <div class="col-md-12 col-12">
    <div id="CaseSheet" class="carousel slide" data-ride="carousel">
    <div class="carousel-inner">
    <form class="caseSheetTextAreas">
    <textarea class="doctorWritenPlace" v-model="records.caseSheet"></textarea>
    </form>
    </div>
    </div>
    </div>
    </div>
    <div class="row mt-2">
    <label class="" for="date">Next consultation date<span class="star">*</span></label>
    <div class="col-lg-4 col-md-5 col-sm-6 col-10">
    <input type="date" id="datepicker" :min="new Date().toISOString().split('T')[0]"
    class="form-control" v-model="records.nextConsultation_date" />
    </div>
    </div>
    <div class="mt-3" v-if="ToggleButton">
    <div class='imageUploadDetails'>
    <div class='row'>
    <div class="col imagesTexts">
    <img class="prescription-image" src="@/assets/images/notes-image.png">
    <p class="prescriptionParaFont">Write prescription on your pad</p>
    </div>
    <div class="col imagesTexts pl-0 pr-0">
    <img class="arrowRightImage" src="@/assets/images/rightArrow.png">
    </div>
    <div class="col imagesTexts">
    <img class="prescription-image" src="@/assets/images/phone-image.png">
    <p class="prescriptionParaFont">Take its photo using yourmobile</p>
    </div>
    <div class="col imagesTexts pl-0 pr-0">
    <img class="arrowRightImage" src="@/assets/images/rightArrow.png">
    </div>
    <div class="col imagesTexts">
    <img class="prescription-image" src="@/assets/images/upload-image.png">
    <p class="prescriptionParaFont">Upload the prescription</p>
    </div>
    </div>
    </div>
    </div>
    <div class="row mt-2">
    <div class="col-lg-4 col-md-5 col-sm-6 col-10 mb-0">
    <label>Upload prescription</label>
    </div>
    <div class="col-lg-8 col-md-7 col-sm-6 col-2 question-icon mb-0" @click="toggle()">
    <font-awesome-icon :icon="['fas', 'question-circle']"
    style="font-size: 25px;" />
    </div>
    </div>
    <div class="row">
    <div class="col-lg-4 col-md-5 col-sm-6 col-10">
    <input type="file" class="form-control" @change="fileSelect($event,index)">
    </div>
    <div class="col-lg-8 col-md-7 col-sm-6 col-2">
        <span class="text-align text-center plus-icon" style="font-size:30px"  >
            <font-awesome-icon  @click="isShowAddConsult()" :class="[!checkConsultationAreEntered ? 'disableCreateBtn' : '']"  :icon='["fas", "plus-circle"]' />
        </span>
    </div>
    </div>
    <!-- <div class="row mt-2 mb-2">
    <div class="col-7 col-md-6 col-sm-6 col-lg-4">
    <label class="" for="date">Phone number<span class="star">*</span></label>
    <input type="text" class="form-control" v-model="publicPayload.mobile" />
    </div>
    </div> -->
    <div class="hr-border"></div>
</div>
<div class="my-3" v-if="otpIsVerified && openOTP">
<button  class="btn btn-blue-color text-white" @click=" saveConsultation()"  :disabled="!isConsultationDataValid"  style="border-radius: 10px;">Close consultation</button>
</div>
    </div>
    </div>
    </div>
    </div>
    </div>
    <doctorRecordsView v-if="issubmit" @clearAll="clearAll()" :mobileData="userPayload" :isUserCreated="isUserCreated" :newPublic="newPublic"  ></doctorRecordsView>
</template>

<script>
import axios from 'axios';
import moment from 'moment';
import doctorRecordsView from '../../user/records/doctorRecordsView.vue'
export default {
components: {
    doctorRecordsView
},
data() {
    return {
getDisorderArray:[],
addPatient: false,
pastPatientRecord: false,
addSchedule: false,
previousConsultation: false,
patient: 0,
upload_Record: [{
caseSheet: "",
nextConsultation_date: "",
uploadImage: "",}],
issubmit: false,
isNot: false,
openSave: false,
loginMobile: "",
mobile: "",
email: "",
openAddPatient: false,
date: '',
fulldate: '',
patientSupport: false,
openOTP: false,
ToggleButton: false,
mobileData: [],
mobileArray: [],
userPayload:{},
publicPayload:{
    age:"",
    gender: '1',
},
isUserCreated:false,
newPublic:null,
doctorPayload:{},
publicDoctorCommunityData:[],
uploadImage: null,
otpNumbersUser: ['', '', '', ''],
otpIsVerified:false,
timeLeftUser: 30,
timerUser: null,
enableResendBtnUser:false,
createPatient:false,
enterOTP:false,
timegreater:true
    }
},
created: function () {
    let DoctorUserId = localStorage.getItem("id");
  this.DoctorId = JSON.parse(DoctorUserId);
    this.date = this.printDate();
    this.getDoctor()
},
computed: {
    combinedOTPUser() {
        if (this.otpNumbersUser.some(input => !/^\d$/.test(input))) {
    return '';
  }
  return this.otpNumbersUser.join('');
},
    isMobileValid() {
        const mobilevalidation = /^[6-9]\d{9}$/;
        return mobilevalidation.test(this.publicPayload.mobile);
    },
    isFormFamilyInvalid() {
const nameRegex = /^[A-Za-z.\s]+$/;
const pincodeValidation = /^[1-9](\s*\d){5}$/;

return (
    nameRegex.test(this.publicPayload.firstName) &&
    nameRegex.test(this.publicPayload.lastName)  &&
    this.publicPayload.age >= 18 && this.publicPayload.age <= 999  && 
    this.publicPayload.gender !== "1"  &&
    this.publicPayload?.gender .trim() !== "" &&
    pincodeValidation.test(this.publicPayload.pincode)
);
},
checkConsultationAreEntered(){
if (this.upload_Record.length === 0) {
return false; // No records to validate
}
for (let i = 0; i < this.upload_Record.length; i++) {
const record = this.upload_Record[i];
if (
  !record.caseSheet ||
  !record.nextConsultation_date 
) {
  return false; 
}
}
return true; 
},
isConsultationDataValid() {
if (this.upload_Record.length === 0) {
return false; // No records to validate
}
const mobileValidation = /^[6-9]\d{9}$/;
const nameRegex = /^[A-Za-z.\s]+$/;
const pincodeValidation = /^[1-9](\s*\d){5}$/;

return (
nameRegex.test(this.publicPayload.firstName) &&
nameRegex.test(this.publicPayload.lastName) &&
this.publicPayload.age >= 18 && this.publicPayload.age <= 999 &&
this.publicPayload.gender !== "1" &&
this.publicPayload?.gender.trim() !== "" &&
pincodeValidation.test(this.publicPayload.pincode) &&
mobileValidation.test(this.publicPayload.mobile) &&
this.upload_Record.every(record =>
  record.caseSheet !== undefined &&
  record.caseSheet !== '' &&
  record.nextConsultation_date !== undefined &&
  record.nextConsultation_date
)
);
} ,
isConsultationDataValidNoCheckbox(){
const mobilevalidation = /^[6-9]\d{9}$/;
return mobilevalidation.test(this.publicPayload.mobile)
}
},
methods: {
    mobileNumberValidate(){
  this.publicPayload.mobile = this.publicPayload.mobile.replace(/\D/g, '');
},
validateInputUser(index) {
  const inputValue = this.otpNumbersUser[index];
  const isValid = /^\d$/.test(inputValue); 
  if (!isValid) {
    this.otpNumbersUser[index] = ''; 
  }
  this.$nextTick(() => {
    if (this.otpNumbersUser[index] !== '' && index < this.otpNumbersUser.length - 1) {
        const otpInput = this.$refs.otpInputs;
        if (otpInput && otpInput.length > 0) {
    otpInput[index+1].focus();
  }
}
});
},
    clearAll(){
       this.clearPatient();
    },
    isShowAddConsult() {
        this.upload_Record.push({
    caseSheet: "",
    nextConsultation_date: null,
    uploadImage: null,
  });
},
    toggle() {
        this.ToggleButton = !this.ToggleButton
    },
    printDate: function () {
        return new Date().toLocaleDateString('en-GB', {
            day: 'numeric',
            month: 'short',
            year: 'numeric'
        }).split(' ').join('-');
    },
    async listPatient() {
        this.openSave=false
        this.patientSupport=false
        this.newPublic=null
        localStorage.setItem('mobile', this.publicPayload.mobile)
     const BASE_API_URL = process.env.VUE_APP_BASE_APIURL
  let payload = {
    mobile: this.publicPayload.mobile,
  };
await  axios
    .post(`${BASE_API_URL}/public-user/get-sign`, payload)
        .then( (response) => {
        this.userPayload = response.data
        if (response?.data ) {
        this.issubmit = true;
        }else  {
        this.isNot = true;
        this.issubmit = false;
        }
        })

        localStorage.setItem('mobile', this.publicPayload.mobile)
        await axios
            .get(`${BASE_API_URL}/add-patient`, {
                headers: {
                    "Content-Type": "application/json",
                },
            })
            .then(async (response) => {
                this.addPatientInfo = response.data;
            })
        await this.addPatientInfo.map((data) => {
            if(data.mobile === this.publicPayload.mobile){

                if (data.mobile === this.publicPayload.mobile) {
                    this.mobileData.push(data)
                }
                else{
                    this.isNot=true
                }
            }
        })
        await  axios
    .post(`${BASE_API_URL}/hcps/get-sign`, payload)
        .then( (response) => {
            this.doctorResponse=response.data
            let mobileNumber = localStorage.getItem('mobile')
            if(this.doctorResponse?.mobile===mobileNumber){
                this.isNot = false;
                this.issubmit = false
               this.$swal("The mobile number entered is of a doctor. Currently, RxIx does not allow the same mobile number for both doctor and a patient. If the doctor is also patient, use a diffrent mobile number.");
            }
        })
        if(this.openAddPatient){
            this.isNot=false
        }
       
    },
    async clearPatient() {
        this.publicPayload.firstName="",
        this.publicPayload.lastName="",
        this.publicPayload.age="",
        this.publicPayload.gender="1",
        this.publicPayload.pincode="",
        this.publicPayload.mobile=""
        this.issubmit = false;
        this.isNot = false;
        this.otpNumbersUser= ['', '', '', ''],
        this.timegreater=true
        clearInterval(this.timerUser);
        this.enableResendBtnUser=false
        this.createPatient=false
        this.otpIsVerified=false
        this.enterOTP=false;
        this.openOTP = false
        this.openAddPatient=false;
        this.upload_Record= [{
        caseSheet: "",
        nextConsultation_date: "",
        uploadImage: "",}]
    },
    patientData() {
        this.selectFamilyRecord = false
        this.selectUserRecord = false
        this.openAddPatient = true
        this.previousConsultation = true
        if(this.openAddPatient){
            this.isNot=false
        }
    },
    changeFirstName(){
  let name =this.publicPayload.firstName.toLowerCase()
  return name.charAt(0).toUpperCase() + name.slice(1);
},
changeLastName(){
  let name =this.publicPayload.lastName.toLowerCase()
  return name.charAt(0).toUpperCase() + name.slice(1);
},
    addPatientData() {
        this.isAgree='';
        this.notAgree=''
        const BASE_API_URL = process.env.VUE_APP_BASE_APIURL
        this.openSave = this.openSave ? false : true;
        this.openSave = this.openSave ? false : true;
        this.openSave = true
        this.patientSupport = true
        let payloadss = {
            firstName: this.changeFirstName(),
            lastName: this.changeLastName(),
            age: this.publicPayload.age,
            gender: this.publicPayload.gender,
            mobile: this.publicPayload.mobile,
            pincode:this.publicPayload.pincode,
            role: "user"
        }
        axios
            .post(`${BASE_API_URL}/add-patient`, payloadss,)
            .then((response) => {
                this.firstName = response.data.firstName;
                this.lastName = response.data.lastName
                this.age = response.data.age;
                this.gender = response.data.gender;
                this.pincode = response.data.pincode;

            })
    },
    fileSelect(event,index) {
        this.upload_Record[index].uploadImage = event.target.files?.[0]
    },
    async compressImage(file) {
        if(file){
return new Promise((resolve) => {
const quality = 0.8; // Adjust quality as needed
const maxWidth = 1000; // Adjust max width as needed
const maxHeight = 1000; // Adjust max height as needed
const image = new Image();

image.onload = () => {
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');
  canvas.width = maxWidth;
  canvas.height = maxHeight;
  ctx.drawImage(image, 0, 0, maxWidth, maxHeight);

  canvas.toBlob(
    (blob) => {
      const compressedFile = new File([blob], file.name, {
        type: 'image/jpeg', // Change the type as needed
        lastModified: Date.now(),
      });
      resolve(compressedFile);
    },
    'image/jpeg', // Change the type as needed
    quality
  );
};

image.src = URL.createObjectURL(file);
});
}
},
getDoctor(){
  const BASE_API_URL = process.env.VUE_APP_BASE_APIURL;
     axios
    .get(`${BASE_API_URL}/hcps/${this.DoctorId}/one`)
    .then((response) => {
        this.doctorPayload=response.data
    })
},
async saveConsultation() {
    const BASE_API_URL = process.env.VUE_APP_BASE_APIURL
    const generalInformation = {
      "id": 24,
      "disorder_id": "24",
      "disorder": "General Information"
      };
      this.getDisorderArray.push(generalInformation)
let payload = {
    firstName: this.changeFirstName(),
    lastName: this.publicPayload.lastName.toUpperCase(),
    age: this.publicPayload.age,
    gender:this.publicPayload.gender,
    mobile:this.publicPayload.mobile,
    getDisordersId: this.getDisorderArray,
    role: "user"
}
axios
    .post(`${BASE_API_URL}/public-user`, payload, {
        headers: {
            "Content-Type": "application/json",
        },
    })
.then(async (response) => {
    if (response?.data) {
        this.newPublic=response.data
        for(let i=0;i<this.upload_Record.length;i++){
        const compressedImage = await this.compressImage(this.upload_Record[i].uploadImage);
        if(compressedImage){
if (compressedImage.size < 22240) {
this.$swal("Image not clear. Please Upload image of better quality.");
return;}}
this.date=moment(this.date).format("YYYY-MM-DD")
let formData = new FormData();
formData.append('doctor_name',this.doctorPayload.firstName);
formData.append('doctor_id',this.doctorPayload.id);
formData.append("caseSheet", this.upload_Record[i].caseSheet)
formData.append('file', compressedImage);
formData.append('isConsultation', 1);
formData.append('nextConsultationDate',this.upload_Record[i].nextConsultation_date)
formData.append("consultation_date", this.date)
formData.append('user_id', this.newPublic.id);
formData.append('family_id', this.familyID);
axios
    .post(`${BASE_API_URL}/upload-records`, formData)
    .then((response) => {
        if (response?.data) {
     this.publicDoctorCommunityData=[];
    this.publicDoctorCommunityData.push(this.doctorPayload)
    const signInPayload ={
        doctorsID: this.publicDoctorCommunityData,
    }
       axios
    .put(`${BASE_API_URL}/public-user/${this.newPublic.id}/update`, signInPayload, {
        headers: {
            "Content-Type": "application/json",
        },
    })
    .then((response) => {
            if(response.data){
            this.clearPatient();
            }
    }) 
    }
    }) }
    }}) 
},
openConsultation(index, id) {
const BASE_API_URL = process.env.VUE_APP_BASE_APIURL
this.openDropData = false
let Payload = {
    doctor_id: this.doctor_id,
    is_otp: true,
    id
}
this.previous = index
this.uploadDataRecord = localStorage.getItem(id);
axios
    .put(`${BASE_API_URL}/family-member/${this.uploadDataRecord}/update`, Payload, {
        headers: {
            "Content-Type": "application/json",
        },
    })
    .then((response) => {
        this.uploadPayloadRecord = response.data;
    })
    .catch(function (error) {
        console.log(error.response);
    });
},
    startTimerUser() {
  if (this.timeLeftUser > 0) {
    this.timegreater=false
this.timerUser = setInterval(() => {
  this.timeLeftUser--;
  if (this.timeLeftUser <= 0) {
    this.timegreater=true
    clearInterval(this.timerUser);
    this.enableResendBtnUser = true;
    this.enterOTP=false
  }
}, 1000);
}
},
    GenerateOTP() {
  const BASE_API_URL = process.env.VUE_APP_BASE_APIURL
let payload = {
    mobile: this.publicPayload.mobile,
  };
 axios
    .post(`${BASE_API_URL}/generateotpuser`, payload)
    .then((response)=>{
        if(response.data.type === 'success'){
    this.timeLeftUser= 30;
    clearInterval(this.timerUser);
        this.startTimerUser();
        this.enterOTP=true;
        this.openOTP = true
this.$nextTick(() => {
        const otpInput = this.$refs.otpInputs;
  if (otpInput && otpInput.length > 0) {
    otpInput[0].focus();
  }
});
        }
    })
    },
    verityOTPUser(){
  const BASE_API_URL = process.env.VUE_APP_BASE_APIURL
        axios
    .get(`${BASE_API_URL}/verifyotpuser/${this.combinedOTPUser}/${this.publicPayload.mobile}`)
    .then((response) => {
      if(response.data.type === 'success'){
        this.createPatient=true
        this.enableResendBtnUser=false
        this.otpIsVerified = true
      } else{
        this.$swal("Please enter valid otp");
      }
    })
    },
    resendOTPUser(){
  const BASE_API_URL = process.env.VUE_APP_BASE_APIURL
 axios
 .get(`${BASE_API_URL}/resendotpuser/${this.publicPayload.mobile}`)
   .then((response)=>{
     if(response.data.type === 'success'){
    this.otpNumbersUser=['', '', '', '']
    this.timeLeftUser=30;
    clearInterval(this.timerUser);
      this.startTimerUser();
      this.enterOTP=true;
      this.$nextTick(() => {
        const otpInput = this.$refs.otpInputs;
  if (otpInput && otpInput.length > 0) {
    otpInput[0].focus();
  }
    });
     }
   })
},
}
};
</script>
<style>
.disableCreateBtn{
color:  #dddddd !important;
pointer-events: none;
cursor: auto; 
}
.Otpinputs{
width: 300px;
margin: auto;
}
.disableBtn{
background: #cccccc !important;
}
#countdowntimer{
color: red;
}
.star {
color: red;
font-size: 20px;
}
div.curveBoxes {
margin: 10px 0px 0px 10px;
background-color: #ffff;
border-radius: 10px;
padding: 15px;
box-shadow: 0 1px 2px 0 rgb(0 0 0 / 16%), 0 1px 2px 0 rgb(0 0 0 / 12%);

}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
-webkit-appearance: none;
margin: 0;
}
.searchBtn{
display: flex;
justify-content: flex-start;
}
.question-icon{
cursor: pointer;
}
.imagesTexts {
text-align: center;
padding-top: 12px;
padding-bottom: 12px;
}

.imageUploadDetails {
position: relative;
width: 427px;
border-radius: 0.25em;
text-align: left;
/* right: 55px; */
/* bottom: 113px; */
z-index: 99;
margin-left: auto;
margin-right: auto;
border: 1px solid #000000;
}

.imageUploadDetails:before,
.imageUploadDetails:after {
position: absolute;
z-index: -1;
content: '';
}

.imageUploadDetails:after {
top: 0;
right: 0;
bottom: 0;
left: 0;
border-radius: inherit;
background: yellow;
}

.imageUploadDetails:before {
border: solid 2px #242424d4;
border-right: solid 3.5em yellow;
bottom: 0.25em;
left: 0.25em;
width: 0;
height: 1em;
transform: rotate(140deg) skewX(75deg);
}
.noRecordfoundMainDiv {
display: flex;
align-items: center;
justify-content: center;
}

.searchPatientText {
margin-top: 0rem;
display: flex;
justify-content: flex-start;
}

.noRecordText {
color: red;
}

.addUserImg {
border: 3px solid #00979e;
height: 100%;
padding: 20px 50px;
margin: 5px;
width: max-content;
}

.addPluesIcon {
width: 100px;
display: block;
box-sizing: border-box;
margin-left: auto;
margin-right: auto;
margin-top: 10px;
cursor: pointer;
}

.text-center.name-fix {
text-align: center !important;
font-size: large;
font-weight: bold;
}

.add-consultation {
margin-left: 1rem;
color: #00979e;
width: 8rem;
}
textarea.doctorWritenPlace {
width: 100%;
height: 200px;
padding: 12px 20px;
box-sizing: border-box;
border: 2px solid #ccc;
border-radius: 4px;
background-color: #ffffff;
font-size: 20px;
}

form.caseSheetTextAreas {
width: 75%;
margin-bottom: auto;
margin-left: auto;
margin-right: auto;
}

.prescription-image {
width: 50px;
height: 50px;
}

.prescriptionParaFont {
font-size: 10px;
margin-bottom: 0px;
width: 87px;
}

.arrowRightImage {
width: 34px;
height: 34px;
margin-top: 8px;
}

@media screen and (max-width:991px) {
div.curveBoxes {
    margin: auto;
    background-color: #ffff;
    border-radius: none;
    box-shadow: none;
}
.EnterOTPdiv {
    margin-top: 1rem;
    text-align:center ;
}
.enterOTPLabelText {
    margin-left: 25px;
}
}

@media screen and (max-width:769px) {
form.caseSheetTextAreas {
    width: 100%;
}
}

@media screen and (max-width:615px) {
.text-center.name-fix {
    font-size: 15px;
}
}
@media screen and (max-width:576px) {
.addUserImg {
    padding: 12px;
}
.addPluesIcon {
width: 60px;
}
}
@media screen and (max-width:530px) {
.prescription-image {
    width: 36px;
    height: 36px;
}

.imageUploadDetails {
    width: 315px;
}

.arrowRightImage {
    width: 20px;
    height: 20px;
    margin-top: 8px;
}

.imagesTexts {
    padding-left: 0px;
    padding-right: 0px;
    margin-right: 1px;
    margin-left: 7px;
}
}

@media screen and (max-width:400px) {
.prescriptionParaFont {
    font-size: 9px;
    margin-bottom: 0px;
    width: 55px;
}

.imageUploadDetails {
    width: 260px;
    padding-left: 5px;
    padding-right: 5px;
}

.imagesTexts {
    padding-left: 0px;
    padding-right: 0px;
    margin-right: 7px;
    margin-left: 9px;
}
}

@media screen and (max-width:391px) {
.text-center.name-fix {
    font-size: 13px;
}

.noRecordText {
    font-size: 19px;
}

.OtpButton {
    font-size: 11px;
}
}
</style>